import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import BlogPost, { BlogProps } from "../blocks/Blog/BlogPost"

const BlogPostTemplate: React.FC<{ pageContext: BlogProps }> = ({
  pageContext,
}) => (
  <Layout>
    <Helmet>
      <title>Paybase - {pageContext.title}</title>
      <meta property="og:title" content={`Paybase - ${pageContext.title}`} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@paybase" />
      <meta name="twitter:creator" content="@paybase" />
      <meta
        property="og:url"
        content={`https://paybase.io/blog/${pageContext.slug}`}
      />
      <meta property="og:title" content={`${pageContext.title}`} />
      <meta property="og:description" content={pageContext.excerpt} />
      <meta
        property="og:image"
        content={
          typeof pageContext.cover.src === "string"
            ? pageContext.cover.src
            : pageContext.cover.src.default
        }
      />
      <meta
        property="twitter:image"
        content={
          typeof pageContext.cover.src === "string"
            ? pageContext.cover.src
            : pageContext.cover.src.default
        }
      />
    </Helmet>
    <BlogPost data={pageContext} />
  </Layout>
)

export default BlogPostTemplate
