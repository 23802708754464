import * as React from "react"
import styled from "styled-components"
import { up, only } from "styled-breakpoints"
import ReactMarkdown from "react-markdown"

import { space, color } from "../../theme"
import Text from "../../components/text"
import Img from "../../components/img"
import { IconLink } from "../../components/link"

import BlogCard from "./BlogCard"
import { BlogProps as BlogPropsExcerpt, randomnumber } from "./utils"
import {
  Author,
  M,
  renderers,
  colorKeys,
  strippedColorKeys,
  IBG,
} from "./components"

export type BlogProps = {
  title: string
  slug: string
  published: string
  author: string
  excerpt: string
  content: string
  cover: ImageType
  tags: string[]
  related: BlogPropsExcerpt[]
}

export type Props = {
  data: BlogProps
}

const SuperWrapper = styled.div`
  position: relative;
  display: grid;
`
const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: stretch;
  margin: 0 auto;
  padding: 0 2rem;
  ${up("tablet")} {
    grid-template-columns: 704px;
  }
  ${up("desktop")} {
    grid-template-columns: 1216px;
  }
`

const Container = styled.section`
  position: relative;
  display: grid;
  grid-template-columns: auto;
  grid-column-gap: ${space("small")};
  grid-row-gap: ${space("small")};
  padding: ${space("large")} ${space("small")};
  align-items: flex-start;
  justify-items: flex-start;
  max-width: 100%;
  margin: 0 auto;
  ${up("tablet")} {
    max-width: 768px;
    padding: ${space("large")} ${space("small")};
    grid-template-columns: auto 50px;
  }
  ${up("desktop")} {
    max-width: 900px;
    grid-row-gap: 1rem;
  }
`

const TagContainer = styled.div`
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row;
  grid-column-gap: 8px;
  justify-self: flex-end;
  transform: scaleX(-1);
  ${up("tablet")} {
    grid-template-columns: repeat(4, 1fr);
  }
  ${up("desktop")} {
    grid-template-columns: repeat(12, auto);
  }
`
const Tag = styled(Text)<{ c: ColorKeys }>`
  padding: 4px 8px;
  transform: scaleX(-1);
  background-color: ${p => color(p.c)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const RelatedPost = styled.div`
  grid-column: 1;
  position: relative;
  display: grid;
  grid-template-columns: auto;
  grid-auto-flow: row;
  grid-gap: ${space("normal")};
  margin-bottom: ${space("normal")};
  border-top: 1px solid ${color("lightgrey")};
  padding-top: ${space("normal")};
  ${only("tablet")} {
    padding: 0 ${space("small")};
    padding-top: ${space("normal")};
  }
  ${up("tablet")} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${up("desktop")} {
    border-top: none;
    padding-top: 0;
    grid-template-columns: repeat(3, 1fr);
  }
`

const StickyContainer = styled.div`
  position: relative;
  display: grid;
  grid-row: 2;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0;
  ${up("tablet")} {
    grid-column: 2;
    grid-row: 1/3;
    position: sticky;
    top: 0;
    right: 0;
    width: 50px;
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: ${space("normal")};
    justify-items: center;
    padding: ${space("small")} 0;
    padding-top: 8rem;
  }
`

const Hero = styled.div`
  grid-column: 1;
  grid-row: 1;
`
const MarkdownC = styled(M)`
  grid-column: 1;
`

const BlogImg = styled(Img)`
  grid-column: 1;
  position: relative;
`
const B = styled.span`
  position: relative;
  display: grid;
  width: 100%;
  justify-items: center;
  margin-top: 0;
  margin-bottom: 2rem;
  img {
    position: relative;
    top: 10px;
    margin: 0 auto;
    z-index: 2;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    ${up("tablet")} {
      top: 20px;
    }
  }
`

const BlogPost: React.FC<Props> = ({
  data: { title, content, author, published, cover, tags, related },
}) => {
  const [url, setUrl] = React.useState("")
  const baseTweet = `Check out this blog from @paybase ${title}`
  const tagsStr = tags.map(t => t.trim().replace(/\s/gim, "")).join(",")
  React.useEffect(() => {
    if (window) setUrl(window.location.toString())
  }, [])
  return (
    <SuperWrapper>
      <Wrapper>
        <Container>
          <StickyContainer>
            <IconLink
              icon="twitter"
              hoverColor="blue"
              bg="transparent"
              shadow="transparent"
              to={`https://twitter.com/share?url=${url}&amp;text=${baseTweet}&amp;hashtags=Paybase,${tagsStr}`}
            />
            <IconLink
              icon="facebook"
              hoverColor="blue"
              bg="transparent"
              shadow="transparent"
              to={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                url
              )}`}
            />
            <IconLink
              icon="linkedin"
              hoverColor="blue"
              bg="transparent"
              shadow="transparent"
              to={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURI(
                url
              )}`}
            />
          </StickyContainer>
          <Hero>
            <Text type="headline">{title}</Text>
            <Text type="h5" color="grey">
              {new Date(published).toLocaleDateString("en-GB", {
                // @ts-ignore
                dateStyle: "medium",
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
              , by <Author color="blue">{author}</Author>
            </Text>
          </Hero>
          <B>
            <BlogImg
              src={{
                default: cover.src,
                mobile: `${cover.src}?fit=fillmax&h=250&max-w=768`,
                tablet: `${cover.src}?fit=fillmax&h=680&max-w=768`,
                desktop: `${cover.src}?fit=fillmax&h=680&max-w=1280`,
              }}
              alt={cover.alt}
              mw="95%"
              mh="auto"
              tw="93.5%"
              th="auto"
              dw="95%"
              dh="auto"
              fit="cover"
            />
            <IBG bg={colorKeys[randomnumber(0, colorKeys.length - 1)]} />
            <IBG
              right
              bg={colorKeys[randomnumber(0, colorKeys.length - 1)]}
              top
            />
          </B>
          <TagContainer>
            {tags.map((tag, idx) => (
              <Tag
                key={`tag_blog-post-${idx}`}
                align="center"
                type="mobileBody"
                color="white"
                c={
                  strippedColorKeys[
                    randomnumber(0, strippedColorKeys.length - 1)
                  ]
                }
              >
                {tag}
              </Tag>
            ))}
          </TagContainer>
          <MarkdownC>
            <ReactMarkdown
              source={content}
              escapeHtml={false}
              renderers={renderers}
            />
          </MarkdownC>
        </Container>
        <RelatedPost>
          {related.map((post, idx) => (
            <BlogCard key={`blog-card-${idx}`} {...post} index={idx} />
          ))}
        </RelatedPost>
      </Wrapper>
    </SuperWrapper>
  )
}

export default BlogPost
